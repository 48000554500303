import "./animatedLetters.css";

const AnimatedLetters = ({ strArray, slowFactor }) => {
  const splitArray = strArray.toString().split("");
  // console.log(splitArray)
  return (
    <span>
      {splitArray.map((char, i) => (
        <span
          key={char + i}
          className="text-animate"
          style={{ "animationDelay": slowFactor + i / 10 + "s" }}
        >
          {char}
        </span>
      ))}
    </span>
  );
};

export default AnimatedLetters;