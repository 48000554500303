// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries
// https://firebase.google.com/docs/web/modular-upgrade#update_imports_to_v9_compat
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/analytics";
// API ref
// https://firebase.google.com/docs/reference/js
// EVENTS
// https://developers.google.com/tag-platform/gtagjs/reference/events

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyD2HzBeaY_6NR7ct6QN3iW0Fz18IRU7aTo",
  authDomain: "digitalopty.firebaseapp.com",
  projectId: "digitalopty",
  storageBucket: "digitalopty.appspot.com",
  messagingSenderId: "987348010469",
  appId: "1:987348010469:web:3959a221823c0d9d855b0c",
  measurementId: "G-PY25921KJD",
};

firebase.initializeApp(firebaseConfig);

export const firebaseAnalytics = firebase.analytics();