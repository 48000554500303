import React from "react";
import "./about.css";
import Danelle from '../../assets/me-about.jpg'
// import Danelle from '../../assets/about-me.jpeg'
import { FaAward, FaMoneyBillWave } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md"
import AnimatedLetters from "../animatedLetters/AnimatedLetters";
import "../animatedLetters/animatedLetters.css";

const About = React.forwardRef((props, ref) => {

  return (
    <section id="about" ref={ref}>
      <h5>Meet Danelle</h5>
      <h2>
        <AnimatedLetters strArray={["About Me"]} slowFactor={1} />
      </h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Danelle} alt="Danelle" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Marketing Experience</h5>
              <small>9+ Years</small>
            </article>

            <article className="about__card">
              <FaMoneyBillWave className="about__icon" />
              <h5>Budget Managed</h5>
              <small>€4+ Million</small>
            </article>

            <article className="about__card">
              <MdManageAccounts className="about__icon" />
              <h5>Accounts Managed</h5>
              <small>300+</small>
            </article>
          </div>

          <p>
            After clocking in 7+ years of in-house experience,
            I transitioned to freelancing—initially part-time and eventually all-in.
          </p>
          <p>
            I've managed over €4M in monthly ad spend, tackling complex campaigns for big names like bet365 and Hostelworld.
          </p>
          <p>
            I've fine-tuned strategies for a select group of ambitious clients, seeing consistent MoM, YoY growth.
            I've also lent my expertise to renowned agencies for team upskilling and operational streamlining.
          </p>
          <p>
            Aside from client projects, I offer
            advanced Google Ads training and personalized coaching sessions for specialists looking to up their game.
          </p>

          <a
            className="btn btn-primary"
            href="https://www.linkedin.com/in/danelle-azzopardi-b06a38100/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Connect on LinkedIn
          </a>
        </div>
      </div>
    </section>
  );
});

export default About;
