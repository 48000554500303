import React from "react";
import "./experience.css";
import AnimatedLetters from "../animatedLetters/AnimatedLetters";
import "../animatedLetters/animatedLetters.css";
import { SiGoogleads, SiLooker, SiTableau, SiGoogleanalytics } from "react-icons/si";
import { FaFacebook, FaTiktok, FaTwitter, FaMicrosoft } from "react-icons/fa";
import { BsLinkedin, BsPatchCheckFill } from "react-icons/bs";
import { RiFileExcel2Fill } from "react-icons/ri";
import { ImGoogleDrive } from "react-icons/im"
import { GiMagnifyingGlass } from "react-icons/gi"

const Experience = React.forwardRef((props, ref) => {

  return (
    <section id="experience" ref={ref}>
      <h5>What Sets Me Apart</h5>
      <h2>
        <AnimatedLetters strArray={["My Experience"]} slowFactor={1} />
      </h2>

      <div className="container experience__container">
        <div className="experience__platforms">
          <h3>Advertising Platforms</h3>
          <div className="experience__content">
            <article className="experience__details">
              <SiGoogleads className="experience__details-icon" />
              <div>
                <h4>Google</h4>
                {/* <small className="text-light">Experienced</small> */}
              </div>
            </article>
            <article className="experience__details">
              <FaMicrosoft className="experience__details-icon" />
              <div>
                <h4>Microsoft</h4>
              </div>
            </article>
            <article className="experience__details">
              <FaFacebook className="experience__details-icon" />
              <div>
                <h4>Meta</h4>
              </div>
            </article>
            <article className="experience__details">
              <FaTwitter className="experience__details-icon" />
              <div>
                <h4>Twitter</h4>
              </div>
            </article>
            <article className="experience__details">
              <BsLinkedin className="experience__details-icon" />
              <div>
                <h4>LinkedIn</h4>
              </div>
            </article>
            <article className="experience__details">
              <FaTiktok className="experience__details-icon" />
              <div>
                <h4>TikTok</h4>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__tools">
          <h3>Tools</h3>
          <div className="experience__content">
            <article className="experience__details">
              <SiGoogleanalytics className="experience__details-icon" />
              <div>
                <h4>Google Analytics</h4>
              </div>
            </article>
            <article className="experience__details">
              <RiFileExcel2Fill className="experience__details-icon" />
              <div>
                <h4>Excel</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiLooker className="experience__details-icon" />
              <div>
                <h4>Looker</h4>
              </div>
            </article>
            <article className="experience__details">
              <SiTableau className="experience__details-icon" />
              <div>
                <h4>Tableau</h4>
              </div>
            </article>
            <article className="experience__details">
              <ImGoogleDrive className="experience__details-icon" />
              <div>
                <h4>G-Suite</h4>
              </div>
            </article>
            <article className="experience__details">
              <GiMagnifyingGlass className="experience__details-icon" />
              <div>
                <h4>Semrush</h4>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Experience;
