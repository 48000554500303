import React, { useRef } from "react";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Experience from "./components/experience/Experience";
import Services from "./components/services/Services";
import Portfolio from "./components/portfolio/Portfolio";
// import Testimonials from "./components/testimonials/Testimonials";
// import Contact from "./components/contact/Contact";
import ContactOptions from "./components/contact/ContactOptions";
import Footer from "./components/footer/Footer";
import CookieConsent from "react-cookie-consent";

const App = () => {
  // forwarding refs
  // https://stackoverflow.com/questions/38093760/how-to-access-a-dom-element-in-react-what-is-the-equilvalent-of-document-getele

  const headerSection = useRef(null);
  const aboutSection = useRef(null);
  const experienceSection = useRef(null);
  const servicesSection = useRef(null);
  const portfolioSection = useRef(null);
  // const testimonialSection = useRef(null);
  const contactSection = useRef(null);

  return (
    <>
      <Header ref={headerSection} />
      <Nav
        positions={{
          headerSection,
          aboutSection,
          experienceSection,
          servicesSection,
          portfolioSection,
          // testimonialSection,
          contactSection,
        }}
      />
      <About ref={aboutSection} />
      <Experience ref={experienceSection} />
      <Services ref={servicesSection} />
      <Portfolio ref={portfolioSection} />
      {/* <Testimonials ref={testimonialSection} /> */}
      {/* <Contact ref={contactSection} /> */}
      <ContactOptions ref={contactSection} />
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="I Agree"
        cookieName="defaultCookie"
        containerClasses="cookie-container"
        buttonClasses="cookie-btn"
        expires={150} // Number of days before the cookie expires.
      >
        We use cookies to improve your browsing experience on our website, to analyze website traffic,
        and to understand where our visitors are coming from. <br />
        By navigating the website, you consent to the use of cookies and other tracking technologies.
      </CookieConsent>
    </>
  );
};

export default App;
