import React from "react";
import "./services.css";
import { BiCheck } from "react-icons/bi";
import AnimatedLetters from "../animatedLetters/AnimatedLetters";
import "../animatedLetters/animatedLetters.css";

const Services = React.forwardRef((props, ref) => {

  return (
    <section id="services" ref={ref}>
      <h5>Here's How I Can Help</h5>
      {/* <h2>Services</h2> */}
      <h2>
        <AnimatedLetters strArray={["Services"]} slowFactor={1} />
      </h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Ads Audit</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Comprehensive review to identify inefficiencies.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Data-driven insights for ROI improvement.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Tailored action plan for immediate impact.</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Consulting</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Designing high-impact marketing strategies.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>One-on-one guidance to optimize campaigns.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Critical metrics monitoring and advice.</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Set up & Mgmt</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Custom campaign setups aligned with your goals.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Hands-on management for continuous optimization.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Regular reporting to keep you in the loop.</p>
            </li>
          </ul>
        </article>
      </div>
      <div className="btn__wrapper">
        <a
          className="btn btn-primary"
          href="mailto:danelle@digitalopty.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get Started with a Free Consultation
        </a>
      </div>
    </section >
  );
});

export default Services;
