import React from "react";
import "./header.css";
import LOGO from "../../assets/DigitalOpty-yw-clearbg.png";
import AnimatedLetters from "../animatedLetters/AnimatedLetters";
import "../animatedLetters/animatedLetters.css";
// import CTA from "./CTA";

const Header = React.forwardRef((props, ref) => {

  return (
    <header id="hello" ref={ref}>
      <div className="container header__container">
        <div className="header__content">
          <h2>
            <AnimatedLetters strArray={["Hello, I'm"]} slowFactor={0.05} />
          </h2>
          <h1>
            <AnimatedLetters strArray={["Danelle"]} slowFactor={1} />
          </h1>
          <h3 className="text-light">Your Go-To Expert for Google Ads and Online Marketing. <br />
            Ready to Boost Your ROI?
          </h3>
          <div className="cta">
            <a href="#contact" className="btn">
              Let's Talk
            </a>
          </div>
        </div>
        <div className="image__container">
          <div className="image__container-image">
            <img src={LOGO} alt="DigitalOpty" />
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
