import React from "react";
import { MdOutlineEmail } from "react-icons/md";
import { BsLinkedin, BsWhatsapp } from "react-icons/bs";
import "./contact.css";
import AnimatedLetters from "../animatedLetters/AnimatedLetters";
import "../animatedLetters/animatedLetters.css";

const ContactOptions = React.forwardRef((props, ref) => {
  return (
    <section id="contact" ref={ref}>
      <h5>Ready to Talk?</h5>
      <h2>
        <AnimatedLetters strArray={["Got questions or ready to start? Let's connect."]} slowFactor={1} />
      </h2>
      <div className="contact__options">
        <a
          href="mailto:danelle@digitalopty.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
          </article>
        </a>
        <a
          href="https://www.linkedin.com/in/danelle-azzopardi-b06a38100/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <article className="contact__option">
            <BsLinkedin className="contact__option-icon" />
            <h4>LinkedIn</h4>
          </article>
        </a>
      </div>
      <div className="contact__content">
        <p>
          Still on the fence? I get it. Committing to an advertising partner is a big deal.
        </p>
        <p>
          You might wonder, 'Will Danelle get my brand?' or 'Can she really make my budget work harder?' <br />
          The answer to both is a resounding 'Yes!'
        </p>
        <p>
          If you've got more questions or just want to chat, I'm all ears. <br />
          Let's achieve remarkable results together.
        </p>
      </div>
    </section>
  );
});

export default ContactOptions;
