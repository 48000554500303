import React, { useEffect } from "react";
import "./nav.css";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { BiMessageSquareDetail } from "react-icons/bi";
// import { IoIosPeople } from "react-icons/io";
import { useState } from "react";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { firebaseAnalytics } from "../firebaseConfig";

const Nav = (props) => {
  const [activeNav, setActiveNav] = useState("#");
  const position = useScrollPosition();
  // const maxScrollHeight = document.body.scrollHeight;

  const handleClick = (id) => {
    const currentURL = window.location.href; // returns the absolute URL of a page
    // const pathname = window.location.pathname; //returns the current url minus the domain name
    firebaseAnalytics.logEvent("page_view", {
      page_location: currentURL,
      page_title: id
    });

    return setActiveNav(id);
  };

  const handleScroll = (id) => {
    return setActiveNav(id);
  };

  useEffect(() => {
    Object.keys(props.positions).forEach((key, index) => {
      const start = props.positions[key].current.offsetTop - 50; // giving some space to look smoother
      const end = props.positions[key].current.nextElementSibling.offsetTop;
      if (position > start && position < end) {
        handleScroll(`#${props.positions[key].current.id}`);
      }
      // console.log(`✅ key is: ${key}, start is ${start} & end is ${end}`);
      // console.log(`✅ key is: ${key}, id is ${props.positions[key].current.id}`);
    });
  });

  return (
    <nav>
      {/* can't use only #
      https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/HEAD/docs/rules/anchor-is-valid.md#case-i-want-navigable-links */}
      <a
        key="#hello"
        href="#hello"
        onClick={() => handleClick("#hello")}
        className={activeNav === "#hello" ? "active" : ""}
      >
        <AiOutlineHome />
      </a>
      <a
        key="#about"
        href="#about"
        onClick={() => handleClick("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <AiOutlineUser />
      </a>
      <a
        key="#experience"
        href="#experience"
        onClick={() => handleClick("#experience")}
        className={activeNav === "#experience" ? "active" : ""}
      >
        <BiBook />
      </a>
      <a
        key="#services"
        href="#services"
        onClick={() => handleClick("#services")}
        className={activeNav === "#services" ? "active" : ""}
      >
        <RiServiceLine />
      </a>
      <a
        key="#contact"
        href="#contact"
        onClick={() => handleClick("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};

export default Nav;
